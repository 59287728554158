import React from 'react'
import _ from 'lodash'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import ConfiguredMUIDatable from '../configuredMuiDatable'
import { renderSingleUserCell } from '../singleUserCell'
import createTableData from './createTableData'
import MatchOpportunitiesCell from './MatchOpportunitiesCell'
import UserDetailPanel from '../UserDetailPanel'

import {
  RemoveMatchOpportunity,
  AddMatchOpportunity,
  InitiateMatchProcess,
  RemoveUser
} from '../../../../client/client'

export default function UnmatchedUsersTable ({
  usersData,
  userType,
  refreshFunc,
  onSuccessFunc,
  onErrorFunc
}) {
  // DO NOT change order. Table functionality relies on it
  const columns = [
    {
      label: 'User',
      name: 'user',
      options: {
        customBodyRender: renderSingleUserCell
      }
    },
    {
      label: 'Search',
      name: 'searchContent',
      options: {
        display: 'excluded',
        searchable: true
      }
    },
    {
      label: 'Match Opportunities',
      name: 'matchOpportunities',
      options: {
        customBodyRender: createRenderMatchOpportunitiesCell(
          userType,
          _.curry(removeMatchOpportunity),
          _.curry(addMatchOpportunity),
          _.curry(initiateMatchProcessAndUpdateUser)
        )
      }
    },
    {
      label: 'JoinFormSubmission',
      name: 'joinFormSubmission',
      options: {
        display: 'excluded'
      }
    }
  ]
  const data = createTableData(usersData, userType)
  const options = {
    selectableRows: 'single',
    expandableRows: true,
    isRowSelectable: isRowSelectable,
    renderExpandableRow: createExpandableRow,
    onRowsDelete: removeUsers
  }

  return (
    <ConfiguredMUIDatable
      title={createTableName(userType)}
      data={data}
      columns={columns}
      options={options}
    />
  )

  // **************************************************
  function removeMatchOpportunity (user1UUID, user2UUID) {
    RemoveMatchOpportunity(user1UUID, user2UUID)
      .then(refreshFunc)
      .catch(alertUserIfUpdateFails)
  }

  function addMatchOpportunity (user1UUID, user2UUID) {
    AddMatchOpportunity(user1UUID, user2UUID)
      .then(refreshFunc)
      .catch(alertUserIfUpdateFails)
  }

  function initiateMatchProcessAndUpdateUser (selectorUUID, event) {
    InitiateMatchProcess(selectorUUID)
      .then(function refresViewAndAlertUser (response) {
        // fires if response.status is within 2xx range
        refreshFunc()
        onSuccessFunc('Success: Initiated Match Process!')
      })
      .catch(alertUserIfUpdateFails)
  }

  function isRowSelectable (dataIndex) {
    // dataIndex -> bool
    // If user has any match opportunities on them, you can't select their row for deletion
    // eslint-disable-next-line
    return data[dataIndex].matchOpportunities.addedOptions.length == 0
  }

  function removeUsers (rowsDeleted) {
    // function(rowsDeleted: object(lookup: {dataindex: boolean},
    // data: arrayOfObjects: {index, dataIndex})) => void OR false
    // (Returning false prevents row deletion.)
    // isSelectable function prevents users who are not amenable to deletion
    // from being selected. We also set table to only allow one selection at a time.
    // So here, just implement the removal of one user.

    // If user has any match opportunities on them, you can't delete them. This
    // is enforced by the isRowSelectable function
    const userDeletedIndex = rowsDeleted.data[0].index
    RemoveUser(data[userDeletedIndex].user.uuid)
      .then(function updateViewAfterRemoval () {
        refreshFunc()
        onSuccessFunc('Succesfully removed user!')
      })
      .catch(alertUserIfUpdateFails)
  }

  function alertUserIfUpdateFails (error) {
    onErrorFunc('Server Error. Please contact Tech Support')
  }
}

// ************************************************************************
function createTableName (userType) {
  return 'Unmatched ' + userType[0].toUpperCase() + userType.slice(1) + 's'
}

function createExpandableRow (rowData, rowMeta) {
  const colSpan = rowData.length + 1
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <UserDetailPanel joinFormSubmission={rowData[3]} />
      </TableCell>
    </TableRow>
  )
}

// Use a closure to add userType, onAddFunc, onRemoveFunc, onInitiateFunc into the namespace
function createRenderMatchOpportunitiesCell (
  userType,
  // curried funcs that takes two uuids. pass in first uuid here to customize the funcs to the relevant row's user
  onRemoveFuncNeedingRootUserUUID,
  onAddFuncNeedingRootUserUUID,
  initiateFuncNeedingRootUserUUID
) {
  return function (value, tableMeta, updateValue) {
    return (
      <MatchOpportunitiesCell
        rowMatchOpportunities={value.addedOptions}
        onRemoveFunc={onRemoveFuncNeedingRootUserUUID(value.rootUserUUID)}
        onAddFunc={onAddFuncNeedingRootUserUUID(value.rootUserUUID)}
        searchOptions={value.optionsToAdd}
        userType={userType}
        activePotentialSelectors={value.activePotentialSelectors}
        onInitiateFunc={initiateFuncNeedingRootUserUUID(value.rootUserUUID)}
        value={value}
        highlightText={tableMeta.tableState.searchText || ''}
      />
    )
  }
}
