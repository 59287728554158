import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

export default function UserDetailPanel ({ joinFormSubmission }) {
  return (
    <Box
      display='flex'
      maxWidth='70%'
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
      margin='0 auto'
      textAlign='center'
      py={2}
    >
      <Typography variant='h5' gutterBottom paragraph>
        User Details
      </Typography>
      <Typography style={{ fontWeight: 'bold' }}>What do you make?</Typography>
      {joinFormSubmission.thingsYouMake}
      <br />
      <br />
      <Typography style={{ fontWeight: 'bold' }}>
        Recent books or articles you've read?
      </Typography>
      {joinFormSubmission.recentBooksArticles}
      <br />
      <br />
      <Typography style={{ fontWeight: 'bold' }}>Areas of Interest?</Typography>
      {joinFormSubmission.areasOfInterest.map(function renderWithLineBreak (a) {
        return (
          <>
            {a}
            <br />
          </>
        )
      })}
      <br />
      <Typography style={{ fontWeight: 'bold' }}>
        Question You're Curious about?
      </Typography>
      {joinFormSubmission.questionYoureCuriousAbout}
      <br />
      <br />
      <Typography style={{ fontWeight: 'bold' }}>
        Question for collaborator?
      </Typography>
      {joinFormSubmission.questionForCollaborator}
      <br />
      <br />
      <Typography style={{ fontWeight: 'bold' }}>
        Highest Education Level
      </Typography>
      {joinFormSubmission.highestEducationLevel}
      <br />
      <br />
      <SampleImageAndCaption
        title='Work Sample 1'
        url={joinFormSubmission.image1Url}
        caption={joinFormSubmission.caption1}
      />
      <SampleImageAndCaption
        title='Work Sample 2'
        url={joinFormSubmission.image2Url}
        caption={joinFormSubmission.caption2}
      />
    </Box>
  )
}

function SampleImageAndCaption ({ title, url, caption }) {
  // eslint-disable-next-line
  const validUrl = typeof url == 'string' && url != ''
  if (!validUrl) {
    return null
  }
  // else
  return (
    <>
      <Typography style={{ fontWeight: 'bold' }}>{title}</Typography>
      <a href={url} target='_blank' rel='noopener noreferrer'>
        Image
      </a>
      {caption || null}
      <br />
      <br />
    </>
  )
}
