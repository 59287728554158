import React from 'react'
import { Button } from '@material-ui/core'

export default function AppButton ({ text, onClick }) {
  return (
    <Button variant='contained' fullWidth onClick={onClick}>
      {text}
    </Button>
  )
}
