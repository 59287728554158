import React, { useState } from 'react'
import HighlightOff from '@material-ui/icons/HighlightOff'
import uuidv4 from 'uuid/v4'
import Box from '@material-ui/core/Box'
import Select, { components } from 'react-select'
import Check from '@material-ui/icons/Check'
import ExpandMore from '@material-ui/icons/ExpandMore'

import { ArtistType, TechnologistType } from '../../../../constants'
import { HighlightSpecialText } from '../../admin/utils'
import AppButton from '../../../functionality/appButton'

export default function MatchOpportunitiesCell ({
  rowMatchOpportunities, // gauranteed to be an array, non-null.
  onRemoveFunc,
  onAddFunc,
  searchOptions,
  userType,
  activePotentialSelectors,
  onInitiateFunc,
  value,
  highlightText,
  ...props
}) {
  const numAddedOptions = Array.isArray(value.addedOptions)
    ? value.addedOptions.length
    : 0
  const addedOptions = numAddedOptions > 0 ? value.addedOptions : []
  return (
    <Box>
      {numAddedOptions === 3 ? (
        <AppButton text={'Initiate'} onClick={onInitiateFunc} />
      ) : (
        <MatchOpportunitySearchBox
          searchOptions={value.optionsToAdd}
          userType={userType}
          rowMatchOpportunities={addedOptions}
          activePotentialSelectors={value.activePotentialSelectors}
          onAddFunc={onAddFunc}
        />
      )}
      <Box py={1} />
      {addedOptions.map(({ name, uuid }) => (
        <React.Fragment key={uuidv4()}>
          <Box display='flex' alignItems='center' pb={1}>
            <HighlightOff onClick={event => onRemoveFunc(uuid)} />
            <Box pl={1} display='inline-block'>
              <HighlightSpecialText
                mainText={name}
                highlightText={highlightText}
              />
            </Box>
          </Box>
        </React.Fragment>
      ))}
    </Box>
  )
}

// ***************************
// Use a closure to add setMenuIsOpen and onAddFunc to local variable context of component
function MakeIndicatorAndConfirmComponent (setMenuIsOpen, onAddFunc) {
  function SearchBoxDropdownIndicatorAndConfirmButton ({
    innerProps,
    hasValue,
    clearValue,
    getValue
  }) {
    return (
      <Box
        {...innerProps}
        display='flex'
        alignItems='center'
        px={0.5}
        onClick={executeAddOrOpenMenu}
      >
        {hasValue ? <Check /> : <ExpandMore />}
      </Box>
    )
    // ***********************************
    function executeAddOrOpenMenu () {
      if (hasValue) {
        clearValue()
        onAddFunc(getValue()[0].value)
      } else {
        setMenuIsOpen(true)
      }
    }
  }

  return SearchBoxDropdownIndicatorAndConfirmButton

  // **********************************************
}

const CustomOption = props => {
  return (
    <components.Option {...props}>
      {props.data.name}
      <br />
      <span style={{ fontSize: '0.75rem', color: 'grey' }}>
        {props.data.email}
      </span>
    </components.Option>
  )
}

function MatchOpportunitySearchBox ({
  searchOptions,
  userType,
  rowMatchOpportunities,
  onAddFunc,
  activePotentialSelectors,
  ...props
}) {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  // Create a data structure to facilitate the proper no programs message
  const oppositeUserTypeMap = {}
  oppositeUserTypeMap[ArtistType] = TechnologistType
  oppositeUserTypeMap[TechnologistType] = ArtistType

  // Define onChange action
  const onChangeFunc = (event, actionMeta) => {
    if (actionMeta.action === 'select-option') {
      setMenuIsOpen(false)
    }
  }

  // Define onInputChange action
  const onInputChangeFunc = (inputValue, actionMeta) => {
    if (actionMeta.action === 'input-change') {
      setMenuIsOpen(true)
    }
    if (actionMeta.action === 'menu-close') {
      // When the user clicks elsewhere on the screen...
      setMenuIsOpen(false)
    }
  }

  return (
    <Select
      options={searchOptions}
      menuIsOpen={menuIsOpen}
      isClearable
      menuPlacement='bottom'
      onChange={onChangeFunc}
      onInputChange={onInputChangeFunc}
      noOptionsMessage={createNoOptionsMessage}
      components={{
        DropdownIndicator: MakeIndicatorAndConfirmComponent(
          setMenuIsOpen,
          onAddFunc
        ),
        Option: CustomOption
      }}
    />
  )

  // ******************************************************
  function createNoOptionsMessage () {
    let message =
      // eslint-disable-next-line
      rowMatchOpportunities.length == 3
        ? 'Max (3) reached'
        : `No available ${oppositeUserTypeMap[userType]}s`
    return message
  }
}
