import {
  RequiredNumberOfMatchOpportunitiesToInitiateMatchProcess,
  ArtistType,
  TechnologistType
} from '../../../../constants'
import {
  getActiveMatchProcessInWhichUserIsSelector,
  getUserTableCellData
} from '../utils'
import _ from 'lodash'

export default function createTableData (usersData, userType) {
  let tableData = []

  usersData.users
    .filter(userHasRightCraftAndIsAvailableForMatching(userType))
    .forEach(addSingleRowData)

  return tableData

  // *********************************************************
  function UUIDtoUUIDAndName (uuid) {
    return {
      uuid: uuid,
      name: UUIDtoName(uuid)
    }
  }

  function UUIDtoName (uuid) {
    if (usersData.usersIndexedByUUID[uuid] == undefined) {
      console.log(usersData.usersIndexedByUUID)
      console.log(uuid)
    }
    return usersData.usersIndexedByUUID[uuid].name
  }

  function addSingleRowData (user) {
    const activePotentialSelectors = getActivePotentialSelectors(user)
    let safeUserMatchOpportunities = Array.isArray(user.matchOpportunities)
      ? user.matchOpportunities
      : []
    let userMatchOpportunitisOptionsToAdd = createMatchOpportunityOptions(
      usersData,
      user,
      activePotentialSelectors,
      userType
    )
    tableData.push({
      user: getUserTableCellData(user),
      searchContent: makeSearchData(
        user,
        safeUserMatchOpportunities.map(UUIDtoName)
      ),
      matchOpportunities: {
        optionsToAdd: userMatchOpportunitisOptionsToAdd,
        addedOptions: safeUserMatchOpportunities.map(UUIDtoUUIDAndName),
        activePotentialSelectors: activePotentialSelectors,
        rootUserUUID: user.uuid
      },
      // Note that we add joinFormSubmission twice. Once as a property on object stored in the user property,
      // and once here. This is because when rendering the user cell, we need to reference joinFormSubmission data. To do that
      //, joinFormSubmission must be in the object stored on the user property. But we also need the joinFormSubmission data
      // when rendering the expansion panel. To do that, it must be a seperate property, since mui-datatable stores the data in the
      // user property as a Symbol type, rather than storing the actual data. That's because we pass a customRender prop for the
      // data that consumes the user property.
      joinFormSubmission: user.joinFormSubmission
    })
  }
}

// ********************************************************
// activePotentialSelectors is an array of UUIDS of users who are a "selector" in an active
// match processes which features this user as a potential selectee.
function getActivePotentialSelectors (user) {
  return user.matchProcesses
    .filter(matchProcessIsActive)
    .map(getMatchProcessSelectorUUID)

  // ****************************
  function matchProcessIsActive (mp) {
    return mp.inProcess
  }

  function getMatchProcessSelectorUUID (mp) {
    return mp.selectorUUID
  }
}

// Returns a single string with all the available search data for the user
function makeSearchData (user, userMatchOpportunities) {
  return `${user.name}${user.email}${user.joinFormSubmission.university}
  ${user.joinFormSubmission.university}${user.joinFormSubmission.program}
  ${user.joinFormSubmission.thingsYouMake}
  ${user.joinFormSubmission.recentBooksArticles}
  ${user.joinFormSubmission.questionYoureCuriousAbout}
  ${user.joinFormSubmission.questionForCollaborator}
  ${user.joinFormSubmission.highestEducationLevel}
  ${user.joinFormSubmission.areasOfInterest}
  ${user.joinFormSubmission.image1Caption}
  ${user.joinFormSubmission.image2Caption}${userMatchOpportunities}`
}

// Returns an array of objects with data on users who are available to the given user
// as a potential match opportunity
function createMatchOpportunityOptions (
  usersData,
  baseUser,
  baseUserActivePotentialSelectors,
  userType
) {
  const numMatchOpportunities = numMachOpsOnUser(baseUser.matchOpportunities)

  let options = [] // If numMatchOpportunities == 3, else:
  // eslint-disable-next-line
  if (numMatchOpportunities != 3) {
    options = usersData.users
      .filter(
        userHasRightCraftAndIsAvailableForMatching(oppositeType(userType))
      ) // is generally available
      .filter(isValidPotentialMatchOpportunity) // is available to this user
      .map(createMatchOpportunityDataObject)
  }

  return options

  // ************************************************************************
  function isValidPotentialMatchOpportunity (potentialMatchOpportunityUser) {
    // eslint-disable-next-line
    // let hasComplementaryCraft = potentialMatchOpportunityUser.craft != userType
    let haventAlreadySelectedThisUser =
      !baseUser.matchOpportunities ||
      !_.includes(
        baseUser.matchOpportunities,
        potentialMatchOpportunityUser.uuid
      )
    let isntASelectorInAnActiveMatchProcessWhereIAmAselectee = !_.includes(
      baseUserActivePotentialSelectors,
      potentialMatchOpportunityUser.uuid
    )
    let hasFewerThanMaximumNumberOfMatchOpportunities =
      numMachOpsOnUser(potentialMatchOpportunityUser) <
      RequiredNumberOfMatchOpportunitiesToInitiateMatchProcess
    return (
      //   hasComplementaryCraft &&
      haventAlreadySelectedThisUser &&
      isntASelectorInAnActiveMatchProcessWhereIAmAselectee &&
      hasFewerThanMaximumNumberOfMatchOpportunities
    )
  }

  function createMatchOpportunityDataObject (user) {
    return {
      label: user.name + user.email, // make it searchable by name and email
      name: user.name,
      value: user.uuid,
      email: user.email
    }
  }

  function numMachOpsOnUser (user) {
    if (user != null) {
      return Array.isArray(user.matchOpportunities)
        ? user.matchOpportunities.length
        : 0
    }
    return 0
  }

  function oppositeType (userType) {
    if (userType == ArtistType) {
      return TechnologistType
    } else {
      return ArtistType
    }
  }
}

function userHasRightCraftAndIsAvailableForMatching (userType) {
  return function (user) {
    // eslint-disable-next-line
    debugger
    let rightCraft = user.craft == userType

    // selectors in an active match process should not show up on unmatched page.
    let notASelectorInAnActiveMatchProcess = _.isEmpty(
      getActiveMatchProcessInWhichUserIsSelector(user)
    )

    // users in an active or upcoming proposal should not show up on unmatched page
    let notInActiveOrUpcomingProposal = _.isEmpty(
      getActiveOrUpcomingProposals(user)
    )

    return (
      rightCraft &&
      notASelectorInAnActiveMatchProcess &&
      notInActiveOrUpcomingProposal
    )
  }
  // *********************************************
  function getActiveOrUpcomingProposals (user) {
    return user.proposals.filter(function proposalIsActiveOrUpcoming (proposal) {
      return proposal.activeOrUpcoming
    })
  }
}
